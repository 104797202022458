import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { WizzyService } from './wizzy.service';
import { WizzyProduct } from '../search-page/search-page.data';
import { Router } from '@angular/router';
import { MixPanelService } from './mixpanel.service';
import { UserIdentifyService } from './user-identify.service';
import { WhatmoreService } from './whatmore.service';
import { MoEngageService } from './moengage.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    trackMap: Map<number, string> = new Map<number, string>();
    eventId: string | null = '';
    productDetailEvents = [
        'view_cart',
        'begin_checkout',
        'add_to_wishlist',
        'add_to_cart',
        'view_item',
    ];

    constructor(
        private wizzyService: WizzyService,
        private route: Router,
        private mixPanelService: MixPanelService,
        private userIdentify: UserIdentifyService,
        private whatMore: WhatmoreService,
        private moEngageService: MoEngageService
    ) {
        firebase.initializeApp(environment.firebaseConfig);
    }

    firebaseAnalytics(eventName: string, eventParams: any, eventID?: string) {
        if (eventName !== 'screen_view') {
            this.mixPanelService.track(eventName, eventParams);
            this.moEngageService.track(eventName, eventParams);
        }
        if (eventName === 'purchase') {
            this.wizzyProductConvertEvent(
                WIZEVENTNAME.PRODUCT_PURCHASED,
                eventParams
            );

            const id = localStorage.getItem(eventParams.transaction_id);
            if (!id) {
                firebase.analytics().logEvent(eventName, eventParams);
                localStorage.setItem(eventParams.transaction_id, 'true');
                // @ts-ignore
                // gtag('event', eventName, eventParams);
                const obj = this.mapFbEvents(eventName, eventParams);
                // @ts-ignore
                fbq('track', 'Purchase', obj, { eventID });
                // this.snapEvent('PURCHASE', eventParams);
                this.mixPanelService.increment('LTV', eventParams.value);
                this.whatMore.WhatmoreEvents(
                    eventParams.transaction_id,
                    eventParams.items
                );
            }
        } else {
            firebase.analytics().logEvent(eventName, eventParams);
            // @ts-ignore
            // gtag('event', eventName, eventParams);
            if (eventName === 'view_item') {
                console.log(eventParams.items);
                const obj = this.mapFbEvents(eventName, eventParams);
                // @ts-ignore
                fbq('track', 'ViewContent', obj, { eventID });
                // this.snapEvent('VIEW_CONTENT', eventParams);
                this.wizzyProductConvertEvent(
                    WIZEVENTNAME.SEARCH_RESULTS_CLICKED,
                    eventParams
                );
            }

            if (eventName === 'add_to_cart') {
                const obj = this.mapFbEvents(eventName, eventParams);
                // @ts-ignore
                fbq('track', 'AddToCart', obj, { eventID });
                // this.snapEvent('ADD_CART', eventParams);
                this.wizzyProductConvertEvent(
                    WIZEVENTNAME.PRODUCT_ADDED_TO_CART,
                    eventParams
                );
            }

            if (eventName === 'screen_view') {
                // @ts-ignore
                fbq('track', 'PageView', eventParams, { eventID });
            }
        }
    }
    analyticsTracking(tracking: string) {
        tracking = atob(tracking.replace(/_/g, '/').replace(/-/g, '+'));
        const obj = JSON.parse(tracking);
        obj.forEach(
            (res: {
                event: string;
                feed?: any[];
                target?: string;
                id?: string;
            }) => {
                const params =
                    res.feed && res.feed.length > 0
                        ? this.queryParamsBreakup(res.feed, {})
                        : {};

                if (res?.target === 'MoEngage') {
                    this.moEngageService.track(res.event, params);
                } else {
                    this.firebaseAnalytics(res.event, params, res?.id);
                }
            }
        );
    }

    queryParamsBreakup(obj: any[], params: any): any {
        obj.forEach((element: any) => {
            switch (element.type) {
                case 'STRING':
                    {
                        params[element.key] = String(element.value);
                    }
                    break;
                case 'INTEGER':
                    {
                        params[element.key] = +element.value;
                    }
                    break;
                case 'FLOAT':
                    {
                        params[element.key] = parseFloat(element.value);
                    }
                    break;
                case 'DOUBLE':
                    {
                        params[element.key] = element.value;
                    }
                    break;
                case 'NESTED':
                    {
                        params[element.key] = [];
                        element.value.forEach((res: any, i: number) => {
                            params[element.key][i] = {};
                            this.queryParamsBreakup(
                                res,
                                params[element.key][i]
                            );
                        });
                    }
                    break;
                case 'LONG':
                    {
                        params[element.key] = Number(element.value);
                    }
                    break;
                case 'ARRAY':
                    {
                        params[element.key] = [...element.value];
                    }
                    break;
            }
        });
        return params;
    }

    setMapValues(tracking: string, route: string) {
        const ciphertext = this.stringToHash(route);
        if (
            !this.trackMap.has(ciphertext) ||
            this.trackMap.get(ciphertext) === undefined
        ) {
            this.trackMap.set(ciphertext, tracking);
        }
        return ciphertext;
    }

    getTrackingValue(route: string) {
        return this.trackMap.get(this.stringToHash(route));
    }

    isBot(userAgent: string): boolean {
        const regexp = new RegExp(/bot|crawler|spider|crawling/);
        //   console.log(regexp.test(userAgent));
        return regexp.test(userAgent);
    }

    mapFbEvents(eventName: string, eventParams: any) {
        switch (eventName) {
            case 'add_to_cart':
            case 'view_item':
                {
                    const obj = {
                        content_type: 'product',
                        value: eventParams?.value,
                        currency: eventParams?.currency,
                        content_ids: [eventParams?.items[0]?.item_id],
                        content_name: eventParams?.items[0]?.item_name,
                        content_category: eventParams?.items[0]?.item_category,
                    };
                    return obj;
                }
                break;
            case 'purchase':
                {
                    const items: { id: any; quantity: any }[] = [];
                    eventParams.items.forEach((res: any) => {
                        items.push({
                            id: res?.item_id,
                            quantity: res?.quantity,
                        });
                    });
                    const obj = {
                        content_type: 'product',
                        value: eventParams?.value,
                        currency: eventParams?.currency,
                        contents: items,
                    };
                    return obj;
                }
                break;
        }
    }

    stringToHash(route: string) {
        let hash = 0;

        for (let i = 0; i < route.length; i++) {
            const ch = route.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash = (hash << 5) - hash + ch;
            // tslint:disable-next-line:no-bitwise
            hash = hash & hash;
        }
        // console.log(hash, 'hash');
        return hash;
    }

    loginRegister(username: string | null, phone: string, email?: string) {
        this.mixPanelService.identify(username || 'default-username');
        this.userIdentify.userIdentify();
    }

    getPhoneNo() {
        const key = `${AwsPool.PREFIX}.${environment.auth.amplify.userPoolWebClientId}.${AwsPool.LAST_USER}`;
        return localStorage.getItem(key);
    }

    commaSeparatedValues(valueArray: any[], key: string) {
        const values: string[] = [];
        valueArray.forEach((res: any) => {
            if (res[key]) {
                values.push(res[key]);
            }
        });
        return values.join(',');
    }

    wizzyProductViewEvent(
        eventName: string,
        product: WizzyProduct | undefined,
        response_id: string | undefined
    ) {
        const items = [{ itemId: product?.id, position: undefined, qty: 1 }];
        const eventData: Record<string, unknown> = {
            name: eventName,
            searchResponseId: response_id,
            items,
        };
        const data: Record<string, unknown> = {
            eventType: 'view',
            eventData,
        };
        this.wizzyService.wizzyViewEvents(data);
    }

    wizzyProductConvertEvent(eventName: string, eventParams: any) {
        const skusString = localStorage.getItem('SearchRecentView') || '{}';
        const savedSkus: Record<string, string> = JSON.parse(skusString) || {};
        const viewSkus: Record<string, unknown>[] = [];
        let value = 0;
        let qty = 0;
        let searchResponseId;
        eventParams.items.forEach((item: any) => {
            const isProductPresent = savedSkus.hasOwnProperty(item.item_id);
            if (isProductPresent) {
                qty++;
                value = value + item.price;
                searchResponseId = savedSkus[item.item_id];
                viewSkus.push({
                    itemId: item.item_id,
                    qty: item.quantity,
                });
            }
        });
        if (viewSkus.length <= 0) {
            return;
        }
        const eventData: Record<string, unknown> = {
            name: eventName,
            value,
            qty,
            items: viewSkus,
            searchResponseId,
        };
        if (eventName === 'purchase') {
            eventData.id = eventParams.transaction_id;
        }

        if (eventName === 'Search Results Clicked') {
            const data: Record<string, unknown> = {
                eventType: 'click',
                eventData,
            };
            this.wizzyService.wizzyConvertedEvents(data);
        } else {
            const data: Record<string, unknown> = {
                eventType: 'converted',
                eventData,
            };
            this.wizzyService.wizzyConvertedEvents(data);
        }
    }
}

export enum FireBaseEvent {
    LOGIN = 'login',
    SIGN_UP = 'sign_up',
    SHARE = 'share',
    SEARCH = 'search',
    OFFERS_VIEW = 'offers_view',
    ACCORDION_TAB_VIEW = 'accordion_tab_view',
}

export enum AwsPool {
    PREFIX = 'CognitoIdentityServiceProvider',
    LAST_USER = 'LastAuthUser',
}

export enum WIZEVENTNAME {
    PRODUCT_VIEW = 'Product Viewed',
    CATEGORY_VIEW = 'Category Viewed',
    SEARCH_RESULTS_CLICKED = 'Search Results Clicked',
    PRODUCT_ADDED_TO_CART = 'Product Added to Cart',
    PRODUCT_PURCHASED = 'Product Purchased',
    PRODUCT_ADDED_TO_WISHLIST = 'Product Added to Wishlist',
}
